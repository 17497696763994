<!-- 第一页 -->
<template>
    <div style="width:22.45rem;height:10.6625rem;overflow: hidden;">
      <!-- 左边 -->
      <div class="tabs">
        <div 
        :class="tabsNum==item.id?'active tab-item':'tab-item'"
         v-for="(item,index) in tabsData" 
         :key="index" 
         @click="changeTab(index)"
          v-loading="loading" 
          element-loading-text="拼命加载中" 
          element-loading-spinner="el-icon-loading" 
          element-loading-background="rgba(255, 255, 255, 0.9)"
        >{{item.title}}</div>
      </div>
      <!-- 右边 -->
      <div class="content" v-loading="loading" 
          element-loading-text="拼命加载中" 
          element-loading-spinner="el-icon-loading" 
          element-loading-background="rgba(255, 255, 255, 0.9)">
        <!-- 标题 -->
        <div class="couseTitle">
          <div class="couseText"><span>{{class_id == 1?'小年龄段':class_id == 2?'大年龄段':class_id == 3?'小班':class_id == 4?'中班':class_id == 5?'大班':''}}</span>课程主题</div>
        </div>
        <!-- 内容 -->
        <div class="couseContentBox">
          <!-- top -->
          <div class="couse-top">
            <!-- photo -->
            <div class="itemBox">
              <img :src="courseData.thumb" style="width:100%;position: absolute;top:50%;left:0;transform: translateY(-50%);">
            </div>
          </div>
          <!-- bottom -->
          <div class="couse-bottom">
            <!-- title -->
            <div class="courseIntroduce">课程介绍</div>
            <!-- Main -->
            <div style="width:100%;height:1.4rem;margin-top:0.4rem;">
              <!-- text -->
              <div class="courseMain">{{courseData.short_description}}</div>
              <!-- LOOK -->
              <div style="width:16.8%;height:100%;float:right;position: relative;">
                <el-button type="primary" style="width:90%;height:0.6rem;font-size:0.21rem;position: absolute;bottom:0;right:0;" @click="lookOver">查看</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import apis from '../../api/request'
// import beiJing from '../../../static/images/beijing.png'
export default {
//import引入的组件需要注入到对象中才能使用
props: ["id"],
components: {
},
data() {
//这里存放数据
return {
  // loading:false,
  schoolId:"",
  courseId:null,
  schoolType:null,
  class_id:"",
  category_id:"",
  course_id:"",
  pic:"",
  courseText:"",
  courseClassify:"",
  ageSection:"",
  courseData:{},
  // pic:"",
  // text:"",
  loading:false,//内容的loading
  courseContent:"",
  tabsNum:0,
  activeIndex:1,
  tabsData:[],
  navData:[],
  personalCenter:{
    name:"admin",
  },//个人中心
};
},
//监听属性 类似于data概念
computed: {
  
},
//监控data中的数据变化
watch: {
  courseClassify(val){
    // console.log(val);
    this.$get(apis.courseCategories, {category_id:val}).then(res=>{
      console.log(res);
    })
  }
},
//方法集合
methods: {
  lookOver(){//进入列表页
    // console.log(this.courseData);
    // this.$get(apis.courseSection+this.courseData.id, {course_id:this.courseData.id}).then(res=>{
    //   console.log(res);
    // })
    this.schoolType = localStorage.getItem("schoolType")
    if(this.schoolType&&this.schoolType == 1){
      this.courseId = localStorage.getItem("courseId")
      if(this.courseId&&this.courseId!=null&&this.courseId!=undefined&&this.courseId!=""){
        this.$router.push(`/details/list/info/${this.courseId}`)
      }
      return
    }
    if(this.schoolType&&this.schoolType == 2){
      this.$router.push(`/details/list/${this.courseData.id}`)
      return
    }
    
  },

  changeTab(index){//切换标签
    console.log(index);
    if(document.getElementsByClassName("tab-item")[index].classList.value.includes("active")){
      this.$message.error("不要重复点击同一个按钮哦")
      return
    }
    this.loading =true
    this.$get(apis.courseDeail+this.tabsData[index].id, {course_id: this.tabsData[index].id}).then(res=>{
      if(res.code === 0){
        this.courseData = res.data.course
        console.log('154', this.courseData.short_description);
        localStorage.setItem("courseId", this.tabsData[index].id)
        localStorage.setItem("courseName", this.tabsData[index].title)
        this.tabsNum = localStorage.getItem("courseId")
        this.loading =false
      }
      this.loading =false
    }).catch(err=>{
      console.log(err);
      this.loading =false
    })
  },
},
beforeCreate() {}, //生命周期 - 创建之前
//生命周期 - 创建完成（可以访问当前this实例）
created() {
},
beforeMount() {}, //生命周期 - 挂载之前
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {
  // console.log("143",this.id);
  this.schoolId = localStorage.getItem("schoolId")
  this.tabsNum = localStorage.getItem("courseId")
  // this.class_id = localStorage.getItem("classId")
  this.course_id = localStorage.getItem("courseId")
  this.category_id = localStorage.getItem("categoryId")
  this.ageSection = localStorage.getItem("ageSection")
  this.loading =true
  this.$get(apis.courseTabs, {category_id:this.category_id,age_section: this.ageSection, school_id:this.schoolId}).then(res=>{
    console.log(res);
    if(res.code === 0){
      this.tabsData = res.data
      this.loading =false
    }
  })
  this.$get(apis.courseDeail+ this.course_id, {course_id: this.course_id}).then(res=>{
    console.log(res);
    if(res.code === 0){
      this.courseData = res.data.course
      console.log('154', this.courseData.short_description);
    }
  })
},
beforeUpdate() {
  // this.$EventBus.$off("getitem")
}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {

}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.itemBox{
    width: 11.4125rem;
    height:5.5rem;
    /* background-color: red; */
    position: absolute;
    overflow: hidden;
    left:50%;
    margin-left:-5.70625rem;
}
.el-menu--horizontal > .is-active.el-menu-item{
  columns: #409eff;
}
.tabs{
    width: 2.425rem;
    height:4.9995rem;
    overflow: auto;
    margin-top:0.8875rem;
    margin-left:2.3375rem;
    background-color: #fff;
    border:1px solid transparent;
    border-radius: 0.1rem;
    float: left;
    position: absolute;
    box-shadow: 0px 6px 36px rgba(135, 135, 135, 0.2);
    z-index: 3;
}
.tab-item{
    width:100%;
    height:1.225rem;
    /* margin:0 auto; */
    /* display: flex; */
    border-radius: 0.1rem;
    overflow: hidden;
    line-height: 1.225rem;
    font-size: 0.225rem;
    /* flex-direction:column; */
    /* justify-content: center; */
    color:#43412DFF;
    /* align-items: center; */
    cursor: pointer;
}
.active{
    background-color:#5B87FF;
    color:#fff;
    box-shadow: 0px 6px 36px rgba(12, 48, 129, 0.23);
}
.content{
    width: 14.04rem;
    height:9rem;
    float: left;
    position: absolute;
    z-index: 3;
    margin-top:0.8875rem;
    margin-left:5.25rem;
    background-color: #fff; 
    box-shadow: 0px 6px 48px rgba(148, 148, 148, 0.13); 
}
.couseTitle{
    width: 3.425rem;
    height: 0.6625rem;
    margin-left: -0.0875rem;
    margin-top: 0.225rem;
    position: relative;
    background-color: #5B87FF;
}
.couseText{
    width: 2.4rem;
    height: 0.4125rem;
    position: absolute;
    top:50%;
    left:50%;
    font-size: 0.3rem;
    margin-left: -1.2rem;
    margin-top: -0.20625rem;
    color: #fff;
}
.couseContentBox{
    width:12.875rem;
    height:7.7125rem;
    /* background-color: yellow;    */
    margin:0.35rem  auto;
    position: relative;
}
.couse-bottom{
    width: 11.4125rem;
    height: 1.75rem;
    position: absolute;
    left:50%;
    margin-left:-5.70625rem;
    display: flex;
    top:5.7rem;
    /* background-color: red; */
}
.couse-top{
    width: 100%;
    height:7.1125rem;
    position: absolute;
    top:0;
}
.courseIntroduce{
  width:1rem;
  height: 0.3rem;
  font-size: 0.225rem;
  font-weight: bold;
  text-align: left;
}
.courseMain{
  height:100%;
  width:83.2%;
  font-size: 0.2rem;
  text-align: left;
  /* text-indent: 2em; */
  float: left;
  overflow: hidden;
  white-space: pre-wrap;
  /* background-color: pink; */
}
</style>